import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import ogImage from "../../images/data-points/global-2000-digital-transformation.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"
import { IconFileText } from "@tabler/icons"

let datasetJson = require("../../data/G2000-majors.json")

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor(row => row["articles"], {
    id: "articles",
    cell: info => (
      <div>
        {info.getValue().map(article => (
          <div className="flex gap-3 items-center max-w-2xl">
            <div>
              <IconFileText size={16} className="text-medium-grey" />
            </div>
            <a
              href={article["url"]} rel="nofollow"
              className="max-w-lg truncate border-b border-b-medium-grey"
              target="_blank"
            >
              {article["title"]}
            </a>
            <div className="flex-1"> </div>
            <div className="text-dark-grey">{article["date"].slice(0, 10)}</div>
          </div>
        ))}
      </div>
    ),
    header: "Top Digital Transformation Updates, Last 3 months",
  }),
  columnHelper.accessor(row => row["score"], {
    id: "score",
    cell: info => (
      <div className="rounded-full shadow-sm w-4 h-4 border-light-grey">
        {info.getValue()}
      </div>
    ),
    header: "Score",
  }),
]

const dataContext = {
  title: "Top Global 2000 companies investing in digital transformation",
  description:
    "Exploring digital transformation initiatives, AI/ML investments, and RPA use among Global 2000 companies.",
  blurb:
    "Exploring digital transformation initiatives, AI/ML investments, and RPA use among Global 2000 companies.",
  ctaText: `Get the whole list of ${datasetJson.length} companies and sync it with your CRM.`,
  slug: "global-2000-digital-transformation",
  freeLimit: 500,
  blogPost: (
    <>
      <p>
        How are Global 2000 companies going through digital transformation to
        stay competitive and meet the rapidly changing demands of the
        marketplace?
      </p>
      <p>
        Digital transformation is critical for large enterprises that want to
        remain competitive and successful in today&#39;s digital economy.{" "}
      </p>
      <p>
        By embracing new technologies (cloud, AI, IoT) and rethinking their
        business models (mobile, ecommerce), companies can improve their
        operations, better serve their customers, and position themselves for
        future growth.
      </p>
      <p>
        <strong>Source</strong>: The source of the company list is the Forbes
        Global 2000 for the year 2021, which is an annual ranking of the top
        2000 public companies globally based on a weighted evaluation of four
        key factors: sales, profit, assets, and market capitalization.
      </p>
      <p>
        Tactic researched each company by looking at sources such as news
        articles, annual reports, job postings, interviews, and others to gather
        information and compile the dataset.
      </p>
      <p>
        <strong>Enrichment</strong>: I looked into different aspects to find
        cool stories about digital transformation. This included finding out
        about new digital projects, discovering how companies are using smart
        computer programs like AI and machine learning, and exploring how robots
        are being used to automate tasks.
      </p>
      <p>
        <strong>Scoring:</strong> When we found a story about a company&#39;s
        digital transformation, we evaluated it based on how recent it is, who
        said it, and if it has any interesting details.
      </p>
      <p>
        We then gave the company a total score by adding up all the scores from
        each story. Finally, we put all the companies in order by their scores
        to see which ones had the highest scores.
      </p>
      <p>
        <strong>Summaries</strong>: To make it easier to understand the stories
        about each company, we picked the three most interesting and best-scored
        stories and made a short summary of them. We put all the summaries and
        links in a table so you can quickly see what&#39;s going on.
      </p>
      <p>
        Need more info from this dataset, like if each company has a top
        executive for digital transformation?{" "}
        <strong>
          <a href="#cta">contact us for a chat</a>!
        </strong>
      </p>
    </>
  ),
  columns: columns,
  published: "Feb 12, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={datasetJson}
      dataContext={dataContext}
      fullVersionOverride={true}
    />
  )
}

export default DataSet
